<template>
  <div>
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Role -->
        <b-col cols="12" md="4">
          <b-form-group
              label="User Role"
              label-for="user-role"
          >
            <select v-model.number="role_id" class="form-control"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
              <option :value="null">Select One</option>
              <option v-for="(role,key) in roles" :value="role.id" :key="key">{{ role.name }}</option>
            </select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <b-form ref="update_form" action="javascript:void(0)" @submit="updatePermission()">
        <!-- PERMISSION TABLE -->
        <b-card
            class="border mt-1"
        >
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon
                  icon="LockIcon"
                  size="18"
              />
              <span class="align-middle ml-50">Permission</span>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col md="3" class="mt-2" v-for="(permission,key) in permissions" :key="key">
                <b-card-title>{{permission.name}}</b-card-title>
                  <b-form-checkbox v-for="(pm,key2) in permission.permissions" name="permissions[]" :value="pm.pivot.id" v-model="current_permissions" :checked="true" :key="key2">{{pm.alias.toLowerCase()}}</b-form-checkbox>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <!-- Action Buttons -->
        <b-button v-if="$can('update','Permission')"
            variant="primary" type="submit"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          Save Changes
        </b-button>
    </b-form>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BForm, BCard, BCardHeader, BCardTitle, BFormCheckbox,
    BCardBody,
} from 'bootstrap-vue'
//import {mapGetters} from "vuex";
import apiCall from "@/libs/axios";
export default {
  components: {
    BButton,
    BRow,BCardBody,
    BCol,
    BFormGroup,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
  },
  data(){
    return{
      role_id:null,
      type:null,
      chk:[],
      current_permissions:[],
    }
  },
  created() {
    this.$store.dispatch('GET_ALL_ROLE');
    this.$store.dispatch('GET_ALL_MENU_PERMISSION');
  },

  methods: {
    updatePermission(){
      let data =new FormData(this.$refs.update_form);
        apiCall.post(`/menu/wise/permission/update${this.role_id}`,data).then((response)=>{
            this.role_id = null;
            this.$toaster.success(response.data.message);
            this.$store.dispatch('GET_ALL_ROLE');
        }).catch((error)=>{
          this.$toaster.success(error.data.message);
        })
    },
    findCurrentPermission(){
      let pm = this.roles.find(item=>parseInt(item.id)===parseInt(this.role_id));
      if(pm){
        this.current_permissions=[];
        this.current_permissions.push(...pm.menu_permissions.map(item=>item.id));
      }
      else{
        this.current_permissions=[];
      }
    }
  },
  computed: {
    permissions(){
      return this.$store.getters.permissions.filter(item=>item.type == parseInt(this.type));
    },
    roles(){
      return this.$store.getters.roles.filter(item=>item.id !== 1);
    }
  },
  watch:{
    role_id(){
      if(this.role_id){
        if(this.role_id===4) this.type=2;
        else if(this.role_id===5) this.type=3;
        else this.type=1;
      }else{
        this.type=null;
      }
      this.findCurrentPermission();
    },
    roles(){
      if(this.role_id){
        if(this.role_id===4) this.type=2;
        else if(this.role_id===5) this.type=3;
        else this.type=1;
      }else{
        this.type=null;
      }
      this.findCurrentPermission();
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
